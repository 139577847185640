import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React from "react";
import TopHeadlineCard from "./TopHeadlineCard";
import stylesgfi from "../pages/ESportLinks/ESportLinks.module.css";

const cards = [
  {
    id: 13,
    title: "The 68th National School Games Rugby 2024-25",
    text: "The 68th National School Games Rugby 7s Boys and Girls competition is being held at Patliputra Sports Complex, Kankarbagh. The Under-19 (boys and girls) competition will be held from December 24-25, Under-17 (boys and girls) from December 28-29, and Under-14 (boys and girls) from January 1-2. The event will provide an opportunity to players of different age groups to showcase their talent.",
    image: "img/nationalSchoolGame24-25.jpg",
    url: "/",
  },
  {
    id: 12,
    title: "Second Wrestling Competition",
    text: "The second wrestling competition of Bihar's traditional wrestling is being organized on 25 and 26 December 2024 at Mansoorchak, N.N. Sinha High School, Bachhwara, Begusarai. This competition is being organized by the Sports Department and Bihar State Sports Authority. A cash prize of total Rs 12.5 lakhs will be given in this competition. The first prize is Rs 1 lakh, second prize is Rs 75 thousand and third prize is Rs 25 thousand. The best player will be given a silver mace.",
    image: "img/SecondWrestlingCompetition.jpg",
    url: "/",
  },
  {
    id: 11,
    title: "Mashaal",
    text: "The country's largest sports talents hunt competition - Mashaal is going to be held for the first time in Bihar. There will be competition in athletics, cycling, football, kabaddi and volleyball. This competition will be organized at school, cluster, block, district and state level. In this, the students selected at the state level will be given a scholarship of up to Rs 3 lakh under the Prerna Scholarship Scheme. The winning students will be given medals, cash as well as certificates.",
    image: "img/Mashal icon.jpg",
    url: "/",
  },
  {
    id: 10,
    title: "32nd National Junior Fencing Championship 2024-25",
    text: "For the first time in Bihar, the 32nd National Junior Fencing Championship 2024-25 has been organized by the Sports Department and Bihar State Sports Authority. It has been organized from 2 to 5 December in the Indoor Hall of Patliputra Sports Complex. It included more than 800 under 20 players from 29 states and Union Territories.",
    image: "img/Fencing icon.jpg",
    url: "/",
  },
  {
    id: 9,
    title: "Bihar Women's Asian Champions Trophy 2024",
    text: "Bihar is hosting the \"Bihar Women's Asian Champions Trophy 2024\" for the year 2024 from 11 - 20 November. The tournament is named \"Hockey Ka Parv, Bihar Ka Garv\", in which six Asian teams from India, Malaysia, China, Korea, Japan and Indonesia are competing.",
    image: "img/ACTHome.jpeg",
    url: "/",
  },

  {
    id: 8,
    title: "First Bihar Volleyball League 2024",
    text: "The first Bihar Volleyball League is going to be organized by Bihar State Sports Authority from 8 to 15 September 2024 at Patliputra Sports Complex, Indoor Stadium, Patna in which 6 teams (Patliputra Acers, Mithila Spikers, Vikramshila Blockers, Magadh Setters, Takshashila Servers and Nalanda Defenders) are participating.",
    image: "img/VallyballFlogo.jpeg",
    url: "/",
  },
  // {
  //   id: 7,
  //   title: "Rajgir Sports University and Sports Academy inauguration",
  //   text: "Rajgir Sports University and Sports Academy was inaugurated by Shri Nitish Kumar, Hon'ble Chief Minister, Bihar on 29 August 2024. It is the first sports university of Bihar. Facilities like hockey turf, swimming pool, football ground, cricket stadium, and indoor stadium have been built in this campus. An exhibition match was also organized on the occasion of inauguration, in which the Indian women's hockey team participated.",
  //   image: "img/Webpage Logo.jpg",
  //   url: "/",
  // },
  {
    id: 6,
    title: "India in Paris Olympic Games 2024 ",
    text: "Paris Olympic Games 2024 is being organized from 26 July 2024 to 11 August 2024. India will participate in Paris Olympics 2024 with 117 athletes, including 70 men and 47 women, in 16 sports disciplines. They will participate in 69 events for 95 medals. Click here for detailed information on the current status of medals by India in Paris Olympics 2024, sports schedule and results.",
    image: "img/parisOlampic.jpeg",
    url: "/",
  },
  // {
  //   id: 5,
  //   title: "ESPORTS Competition 2023",
  //   text: "For the first time in Bihar, an ESPORTS competition has been organized by Bihar State Sports Authority from 16th December to 22nd December 2023 at Patliputra Sports Complex, Kankarbagh, Patna. The grand finale of this competition has been scheduled on 28th December 2023 at Patliputra Sports Complex itself. Under this competition, players can register themselves for 4 different online games.",
  //   image: "img/E_Sports_fh.jpg",
  //   url: "/eSportLinks",
  // },
  // {
  //   id: 4,
  //   title: "The 10th season of Pro Kabaddi League- 2023-24",
  //   text: "The 10th season of Pro Kabaddi League is being organized from 2nd December 2023 to 21st February 2024. This year, Bihar State Sports Authority is sponsoring the team 'Patna Pirates'.",
  //   image: "img/ProCbaddi.jpg",
  //   url: "/prokabaddi",
  // },
  // {
  //   id: 3,
  //   title: "Chess Championship 2024",
  //   text: "Chess Championship is being organized by Bihar State Sports Authority from 5th February 2024 to 10th February 2024.",
  //   image: "img/Chessss.jpg",
  //   url: "#",
  // },
  // {
  //   id: 1,
  //   title: "67th National School Games 2023",
  //   text: "The 67th National School Games 2023 is being organized in Bihar by the School Games Federation of India in 05 sports disciplines – Athletics, Cricket, Sepak Takraw, Weightlifting and Football.",
  //   image: "img/sixty_seventh_sgfi.jpg",
  //   url: "/national-school-games",
  // },
  // {
  //   id: 2,
  //   title: "National Games - 2023",
  //   text: "The National Games is being organized from 25 October to 09 November 2023. Many legendary players who have made India proud on the world stage have participated in this competition. The first National Games was organized in the year 1924 in Lahore city of undivided India. While 36 different sports disciplines were included in the National Games in the year 2022, this time the number of sports disciplines has increased to 43.    ",
  //   image: "img/national-games.jpeg",
  //   url: "/nationalgames",
  // },
];

const NewTopHeadline = () => {
  return (
    <div
      className="container"
      style={{
        borderRadius: "8px",
        marginTop: "15px",
        marginBottom: "5px",
      }}
    >
      <Tabs isManual variant="enclosed">
        <TabList>
          <Tab className={`${stylesgfi.text_tab}`} fontSize="2xl" as="b">
            The 68th National School Games Rugby 2024-25
          </Tab>
          <Tab className={`${stylesgfi.text_tab}`} fontSize="2xl" as="b">
            Second Wrestling Competition
          </Tab>
          <Tab className={`${stylesgfi.text_tab}`} fontSize="2xl" as="b">
            Mashaal
          </Tab>
          <Tab className={`${stylesgfi.text_tab}`} fontSize="2xl" as="b">
            32nd National Junior Fencing Championship 2024-25
          </Tab>
          <Tab className={`${stylesgfi.text_tab}`} fontSize="2xl" as="b">
            Bihar Women's Asian Champions Trophy 2024
          </Tab>
          <Tab className={`${stylesgfi.text_tab}`} fontSize="2xl" as="b">
            First Bihar Volleyball League 2024
          </Tab>
          {/* <Tab className={`${stylesgfi.text_tab}`} fontSize="2xl" as="b">
            Rajgir Sports University and Sports Academy inauguration
          </Tab> */}
          <Tab className={`${stylesgfi.text_tab}`} fontSize="2xl" as="b">
            India in Paris Olympic Games 2024
          </Tab>
          {/* <Tab className={`${stylesgfi.text_tab}`} fontSize="2xl" as="b">
            ESPORTS Competition 2023
          </Tab>
          <Tab className={`${stylesgfi.text_tab}`} fontSize="2xl" as="b">
            The 10th season of Pro Kabaddi League
          </Tab> */}
          {/* <Tab className={`${stylesgfi.text_tab}`} fontSize="2xl" as="b">
            Chess Championship 2024
          </Tab> */}
          {/* <Tab className={`${stylesgfi.text_tab}`} fontSize="2xl" as="b">
            67th National School Games 2023
          </Tab> */}
          {/* <Tab fontSize="2xl" as="b">
            National Games - 2023
          </Tab> */}
        </TabList>
        <TabPanels
          style={{
            borderLeft: "1px solid #cfd9de",
            borderRight: "1px solid #cfd9de",
            borderBottom: "1px solid #cfd9de",
            borderRadius: "0px 0px 5px 5px",
          }}
        >
          {/* initially mounted */}

          {cards.map((card, index) => (
            <TabPanel key={index}>
              <TopHeadlineCard card={card} />
            </TabPanel>
          ))}

          {/* <TabPanel>
            <p>one!</p>
          </TabPanel>
          initially not mounted
          <TabPanel>
            <p>two!</p>
          </TabPanel> */}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default NewTopHeadline;
