import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import api from "../../utils/ApiMethod";
import { AspectRatio } from "@chakra-ui/react";
import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  VStack,
  Avatar,
  GridItem,
  Grid,
  Image,
  useColorModeValue,
  SimpleGrid,
  keyframes,
  background,
  flexbox,
  position,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Helmet } from "react-helmet-async";


// import key frame from chakra ui
const animation = keyframes`
from {
 background:inherit
}
to{
    background:gray.100
}
`;

const Videos = () => {
  const myAnimation = `${animation} infinite 1s`;

  const [data, setData] = useState([]);

  const radialGradient = "radial-gradient(circle, #ff9900, #ff6600)";
  const radialGradient2 = "radial-gradient(circle, #ffefba, #ffffff);";

  const handleFetchData = async () => {
    try {
      const data = await fetch("https://bssa-database.vercel.app/videos");
      const res = await data.json();
      setData(res);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);
  console.log(data, "data");

  const verticalLineStyle = {
    border: "none",
    borderLeft: "2px solid black",
    height: "100px", // Adjust as needed
    margin: "0 10px", // Adjust spacing
    transform: "rotate(90deg)",
    color: "black",
  };

  // console.log("video", data);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Videos - Bihar State Sports Authority</title>
        <meta name="description" content="Watch videos of sports events, training sessions, and initiatives by the Bihar State Sports Authority." />
      </Helmet>

      <Breadcrumb
        pageName="Videos"
        pageTitle="Videos"
        id="#videos"
        img_url="./img/Gallery-desktop.jpg"
        mobile_banner="./img/Gallery-mobile.jpg"
      />
      <div
        style={{
          background:
            "linear-gradient(to bottom right, #ffffff, #f0f0f0, #87CEFA, #B0E0E6)",
        }}
      >
        {/*  <Container w='100%' align='center' m="auto"  border="2 gray.500"> */}
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          fontSize={["18", "42"]}
          letterSpacing="wide"
          color="teal.800"
          px={[4, 10, 20]}
          py={[2, 5, 10]}
        >
          Videos
        </Text>

        {/* ------------------------------------horizontal Line---------------------------------------------------- */}
        <div
          style={{
            height: "4px",
            width: "100%",
            backgroundColor: " rgb(8, 44, 44)",
            paddingBottom: "3px",
          }}
        ></div>
        {/* ------------------------------------------------------------------------------------------------------- */}

        <Center
          position="relative"
          alignContent={"center"}
          alignItems={"center"}
          pt={"20px"}
          pb={"50px"}
        >
          <Grid
            templateColumns={[
              "1fr",
              "1fr 1fr",
              "1fr 1fr 1fr",
              "1fr 1fr 1fr 1fr",
            ]}
            alignItems={"center"}
          >
            {data.map((item, index) => (
              <Box
                key={index}
                pt="10%"
                // pl={"16%"}
                p="5"
                transition="transform 0.3s ease-in-out"
                _hover={{ transform: "scale(1.1)" }}
              >
                <motion.div
                  key={index}
                  initial={{
                    opacity: 0,
                    translateX: index % 2 === 0 ? -50 : 50,
                    translateY: -50,
                  }}
                  whileInView={{ opacity: 1, translateX: 0, translateY: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.2 }}
                >
                  {/*---------------------------- This video will have equal sides--------------------- */}

                  <AspectRatio maxW="560px" ratio={1} bg={"pink"}>
                    <iframe title="naruto" src={item?.video} allowFullScreen />
                  </AspectRatio>

                  <Text>{item?.eventName}</Text>
                </motion.div>
              </Box>
            ))}
          </Grid>
        </Center>
      </div>
    </div>
  );
};

export default Videos;
