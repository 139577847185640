import React from "react";
import { GridItem, SimpleGrid, Box, background } from "@chakra-ui/react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";

const Infrastructure = () => {
  return (
    <div>
      <Breadcrumb
        pageName="Infrastructure"
        pageTitle="Infrastructure"
        id="#infrastructure"
        img_url="./img/Mission-and-vision-page-desktop.jpg"
        mobile_banner="./img/Mission-and-vision-page-mobile.jpg"
      />

      <GridItem
        paddingBottom={"40px"}
        paddingTop={"40px"}
        bgGradient="linear(to-b, blue.700, black)"
        // bgGradient="linear-gradient(to b, blue, black)"
        color={"white"}
      >
        <h1> Basic Infrastructure </h1>
      </GridItem>

      <SimpleGrid
        column={1}
        padding={"10px"}
        paddingLeft={"5%"}
        paddingRight={"5%"}
        paddingBottom={"40px"}
        // paddingTop={'40px'}
        bg={"black"}
      >
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  color={"white"}
                  fontSize={"2rem"}
                >
                  Basic Infrastructure
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} color={"white"} textAlign={"left"}>
              The office of Bihar State Sports Authority is located at
              Patliputra Sports Complex. In this two-storey building adjacent to
              the Indoor Stadium, the offices of the Bihar State Sports
              Authority employees including the Director General cum Chief
              Executive Officer, Director cum Secretary and Sports Executive of
              Bihar State Sports Authority are located. All the members of the
              authority have an important contribution in the all-round
              development of sports and players across the state. This
              two-storey building also has two conference halls for holding
              sports related events. Bihar State Sports Authority is always
              committed to the overall development of sports and players in the
              entire state. Well-organized arrangements have been made for the
              practice of different sports and practice spaces have been
              arranged for players of sports like rugby, fencing, basketball,
              boxing, athletics, badminton, kabaddi, wrestling, weight lifting
              etc. in Patliputra Sports Complex where everyday a large number
              of young players of the state gather for practice to enhance their
              sporting skills.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </SimpleGrid>
    </div>
  );
};
export default Infrastructure;
