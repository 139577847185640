import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

const SportsSponsOrg = () => {
  return (
    <div>
      <Breadcrumb
        pageName="Infrastructure"
        pageTitle="Infrastructure"
        id="#infrastructure"
        img_url="./img/Mission-and-vision-page-desktop.jpg"
        mobile_banner="./img/Mission-and-vision-page-mobile.jpg"
      />
      <h1 style={{ margin: "10px auto" }}>Sports Sponsoring Organizations</h1>

      <Box>
        <TableContainer
          boxShadow={"lg"}
          alignContent={"center"}
          justifyContent={"center"}
          marginRight={"4%"}
          marginLeft={"4%"}
          paddingTop={"5px"}
          paddingBottom={"10px"}
        >
          <Table variant="striped" colorScheme="teal">
            <Thead>
              <Tr>
                <Th fontSize={"1.5rem"}>Sl. No</Th>
                <Th fontSize={"1.5rem"}>Sponsor</Th>
                <Th fontSize={"1.5rem"}>Sports</Th>
              </Tr>
            </Thead>

            {/* {dso.map((item, i) => (
              <Tbody>
                <Tr key={i}>
                  <Td>{i + 1}</Td>
                  <Td>{item?.name}</Td>
                  <Td>{item?.mob}</Td>
                  <Td>{item?.designation}</Td>
                  <Td>{item?.game}</Td>
                </Tr>
              </Tbody>
            ))} */}

            <Tbody>
              <Tr>
                <Td>1</Td>
                <Td>Bihar Police Bhawan Nigam, Patna Bihar</Td>
                <Td>Football </Td>
              </Tr>
              <Tr>
                <Td>2</Td>
                <Td>Bihar State Power Holding Company Limited</Td>
                <Td>Weightlifting </Td>
              </Tr>
              <Tr>
                <Td>3</Td>
                <Td>Bihar Rajya Pul Nirman Nigam Limited </Td>
                <Td>Cycling </Td>
              </Tr>
              <Tr>
                <Td>4</Td>
                <Td>Bihar State Road Development Corporation Limited</Td>
                <Td>Shooting </Td>
              </Tr>
              <Tr>
                <Td>5</Td>
                <Td>Bihar Medical Services and Infrastructure Corporation </Td>
                <Td>Badminton </Td>
              </Tr>
              <Tr>
                <Td>6</Td>
                <Td>Bihar Urban Infrastructure Development Corporation Ltd (BUIDCO)</Td>
                <Td>Volleyball </Td>
              </Tr>
              <Tr>
                <Td>7</Td>
                <Td>Bihar State Building Construction Corporation Limited</Td>
                <Td>Athletics </Td>
              </Tr>
              <Tr>
                <Td>8</Td>
                <Td>Bihar State Educational Infrastructure Development Corporation</Td>
                <Td>Rugby </Td>
              </Tr>
              <Tr>
                <Td>9</Td>
                <Td>Bihar State Warehousing Corporation</Td>
                <Td>Chess </Td>
              </Tr>
              <Tr>
                <Td>9</Td>
                <Td>Bihar Police Building Construction Corporation, Patna Bihar </Td>
                <Td>Football </Td>
              </Tr>
              <Tr>
                <Td>10</Td>
                <Td>Bihar State Electronics Development Corporation Limited</Td>
                <Td>Hockey </Td>
              </Tr>
              <Tr>
                <Td>11</Td>
                <Td>Bihar State Mining Corporation Limited </Td>
                <Td>Kabaddi </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};
export default SportsSponsOrg;
