import React from "react";
import {
  GridItem,
  SimpleGrid,
  Box,
  background,
  Button,
  Stack,
  Card,
  CardBody,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
  CardFooter,
  Image,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";

const ContactUs = () => {
  return (
    <div>
      <Breadcrumb
        pageName="Contact us"
        pageTitle="Contact us"
        id="#contact-us"
        img_url="./img/Message-banner-desktop.jpg"
        mobile_banner="./img/Mission-and-vision-page-mobile.jpg"
      />

      <div style={{ backgroundColor: "#187446" }}>
        <SimpleGrid
          columns={[1, 1, 1, 2]}
          gap={10}
          w="90%"
          m="0px auto 0px auto"
          paddingTop={10}
        >
          <GridItem>
            <Stack
              spacing={4}
              boxShadow="outline"
              rounded="md"
              p="15px 20px"
              bg="#cfd9de"
            >
              <Card
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
                alignItems="center"
                p="8"
                rounded="xl"
              >
                <Button colorScheme="red">
                  <i class="fas fa-map-marker-alt"></i>
                </Button>

                <Stack>
                  <CardBody>
                    <Heading size="lg" textAlign="justify">Address</Heading>

                    <Text py="5" size="md">
                      Bihar State Sports Authority Patna
                    </Text>
                  </CardBody>

                  {/* <CardFooter>
                  <Button variant="solid" colorScheme="blue">
                    Buy Latte
                  </Button>
                </CardFooter> */}
                </Stack>
              </Card>

              <Card
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
                alignItems="center"
                p="8"
                rounded="xl"
              >
                <Button colorScheme="red">
                  <i class="fas fa-map-marker-alt"></i>
                </Button>

                <Stack>
                  <CardBody>
                    <Heading size="lg" textAlign="justify">Stadium</Heading>

                    <Text py="5" size="md">
                      Patliputra Sports Complex, Kankarbagh, Patna
                    </Text>
                  </CardBody>

                  {/* <CardFooter>
                  <Button variant="solid" colorScheme="blue">
                    Buy Latte
                  </Button>
                </CardFooter> */}
                </Stack>
              </Card>

              <Card
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
                alignItems="center"
                p="8"
                rounded="xl"
              >
                <Button colorScheme="blue">
                  <i class="fas fa-phone"></i>
                </Button>

                <Stack>
                  <CardBody>
                    <Heading size="lg" textAlign="justify">Contact No.</Heading>

                    <Text py="5" size="md">
                      +91-00000-2390268
                    </Text>
                  </CardBody>

                  {/* <CardFooter>
                  <Button variant="solid" colorScheme="blue">
                    Buy Latte
                  </Button>
                </CardFooter> */}
                </Stack>
              </Card>
            </Stack>
          </GridItem>

          <GridItem>
            <Box
              boxShadow="outline"
              p="12"
              rounded="md"
              bg="white"
              className=""
              //   style={{ filter: "grayscale(100%)" }}
              style={{ height: "455px" }}
            >
              <h2>Contact Us</h2>

              <FormControl>
                <FormLabel style={{ fontSize: "19px" }}>First name</FormLabel>
                <Input type="fname" style={{ height: "35px" }} placeholder="First name" />
                <FormLabel style={{ fontSize: "19px" }}>Last name</FormLabel>
                <Input type="lname" style={{ height: "35px" }} placeholder="Last name" />
              </FormControl>
              <FormControl>
                <FormLabel style={{ fontSize: "19px" }}>Email-Id:</FormLabel>
                <Input type="email" style={{ height: "35px" }} placeholder="Email-Id" />
                <FormLabel style={{ fontSize: "19px" }}>Phone No.:</FormLabel>
                <Input type="Phone" style={{ height: "35px" }} placeholder="Phone No." />
              </FormControl>
              <FormControl>
                <FormLabel style={{ fontSize: "19px" }}>Message</FormLabel>
                <Input type="message" style={{ height: "35px" }} placeholder="Message..." />
                <Button
                  mt={6}

                  colorScheme="teal"
                  // isLoading={props.isSubmitting}
                  type="submit"
                  style={{ fontSize: "19px", padding: "15px" }}
                >
                  Submit
                </Button>
              </FormControl>
            </Box>
          </GridItem>
        </SimpleGrid>

        {/* <SimpleGrid column={1} w="90%" m="auto">
          <GridItem
            border={"1px solid lightblue"}
            borderRadius={"8px"}
            alignItems={"center"}
            color={"white"}
            paddingTop={"1%"}
            paddingBottom={"1%"}
            _hover={{
              bg: "white",
              color: "black",
            }}
            fontSize={"2rem"}
          >
            माननीय मंत्री,(कला,संस्कृति एवं युवा विभाग )
          </GridItem>
          <GridItem
            paddingLeft={"50%"}
            paddingTop={".5%"}
            paddingBottom={".5%"}
            color={"white"}
          >
            <img src="img/gg.png" style={{ width: "13px" }} />
          </GridItem>

          {/* ============================================ */}
        {/* <GridItem
            border={"1px solid lightblue"}
            borderRadius={"8px"}
            alignItems={"center"}
            color={"white"}
            paddingTop={"1%"}
            paddingBottom={"1%"}
            _hover={{
              bg: "white",
              color: "black",
            }}
            fontSize={"2rem"}
          >
            अपर मुख्य सचिव,(कला,संस्कृति एवं युवा विभाग )
          </GridItem>
          <GridItem
            paddingLeft={"50%"}
            paddingTop={".5%"}
            paddingBottom={".5%"}
          >
            <img src="img/gg.png" style={{ width: "13px" }} />
          </GridItem>

          <GridItem
            border={"1px solid lightblue"}
            borderRadius={"8px"}
            alignItems={"center"}
            color={"white"}
            paddingTop={"1%"}
            paddingBottom={"1%"}
            _hover={{
              bg: "white",
              color: "black",
            }}
            w={"100%"}
            fontSize={"2rem"}
          >
            महानिदेशक सह मुख्य कार्यकारी अधिकारी,( बिहार राज्य खेल प्राधिकरण ){" "}
          </GridItem>
          <GridItem
            paddingLeft={"50%"}
            paddingTop={".5%"}
            paddingBottom={".5%"}
          >
            <img src="img/gg.png" style={{ width: "13px" }} />
          </GridItem>

          <GridItem
            border={"1px solid lightblue"}
            borderRadius={"8px"}
            alignItems={"center"}
            color={"white"}
            paddingTop={"1%"}
            paddingBottom={"1%"}
            marginBottom={"5.5%"}
            _hover={{
              bg: "white",
              color: "black",
            }}
            fontSize={"2rem"}
          >
            निदेशक सह सचिव,(बिहार राज्य खेल प्राधिकरण )
          </GridItem>

        </SimpleGrid> */}

        <SimpleGrid
          columns={[1, 1, 1, 1]}
          gap={5}
          w="90%"
          m="0px auto 0px auto"
          paddingTop={10}
          paddingBottom={10}
        >
          <GridItem>
            <Box
              boxShadow="outline"
              p="2"
              rounded="md"
              bg="white"
              className=""
              //   style={{ filter: "grayscale(100%)" }}
              style={{}}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28785.863667417623!2d85.1391295!3d25.5971741!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed592fc4cedecb%3A0x4e5bdb0f099cecf0!2sBihar%20State%20Sports%20Authority!5e0!3m2!1sen!2sin!4v1692875071145!5m2!1sen!2sin"
                width="100%"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Box>
          </GridItem>
        </SimpleGrid>
      </div>
    </div>
  );
};
export default ContactUs;
