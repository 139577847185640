import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import Header from './components/Header';
import Footer from './components/Footer';
import Loader from './components/Loader';
import MaintenancePage from './pages/Maintenance';
import { HelmetProvider } from 'react-helmet-async';






class RootComponent extends React.Component {
  constructor(props) {
    super(props);
    // Check if the flag is set in local storage
    const shouldDisplayLoader = !localStorage.getItem('loaderDisplayed');
    this.state = {
      loading: shouldDisplayLoader,
    };
  }

  // componentDidMount() {
  //   // Simulate a loading delay (replace with actual data fetching logic)
  //   setTimeout(() => {
  //     this.setState({ loading: false });
  //   }, 3000); // 2 seconds delay

  //   // You can include actual data fetching logic here
  // }



  // new code from here


  componentDidMount() {
    // Set the flag in local storage so the loader won't be displayed on subsequent refreshes
    localStorage.setItem('loaderDisplayed', 'true');

    // Simulate a loading delay (replace with actual data fetching logic)
    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000); // 3 seconds delay
  }



  render() {
    const { loading } = this.state;

    return (
      <React.StrictMode>
        {/* <ChakraProvider>
          <Header /> */}
        <HelmetProvider>
          {loading ? (
            <Loader />// Display the loader while loading
          ) : (
            <ChakraProvider>
              {/* <MaintenancePage /> */}
              <Header />

              <App />
              <Footer />
            </ChakraProvider>
          )}

          {/* <Footer />
        </ChakraProvider> */}
        </HelmetProvider>
      </React.StrictMode>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);
reportWebVitals();





































// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <ChakraProvider>
//       <Header />

//       <App />
//       <Footer />
//     </ChakraProvider>
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
