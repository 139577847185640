import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import api from "../utils/ApiMethod";

const NewsScroller = () => {
  const [newsData, setNewsData] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/api/news");
      setNewsData(data.newData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <motion.div
    // initial={{ opacity: 0, scale: 0 }}
    // whileInView={{ opacity: 1, scale: 1 }}
    // // animate={{opacity:1, translateX:0}}
    // transition={{ duration: 0.5 }}
    >
      <motion.div class="col-sm-12 col-md-4 col-lg-4">
        <h4 class="section-heading mobile-heading" style={{ color: "white" }}>
          News
        </h4>
        <div
          class="lastestNews-container"
          style={{
            scrollBehavior: "smooth",
            overflowY: "scroll",
            scrollbarColor: "teal",
            scrollbarWidth: "thin",
          }}
        >
          <style>
            {`
    ::-webkit-scrollbar {
      width: 15px; /* Adjust the width of the scrollbar */
    }

    ::-webkit-scrollbar-thumb {
      background-color: #fcb606; /* Customize the thumb color */
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: darkteal; /* Customize the thumb color on hover */
    }
    `}
          </style>
          {newsData &&
            newsData.map((elem, index) => (
              <div className="lastestNews" key={index}>
                <a
                  href={elem?.postUrl}
                  className="lastestNews__link"
                  target="_blank"
                >
                  <div className="lastestNews__inner ">
                    <div className="lastestNews__image">
                      <img
                        src={`https://api.biharsports.org/${elem?.imageUrl.replace(
                          "public/images/",
                          "images/"
                        )}`}
                        alt="news-image"
                        className="img-animate"
                      />
                    </div>
                    <div className="lastestNews__content">
                      <h5 className="lastestNews__content__title">
                        {elem?.title}
                      </h5>
                      <p className="lastestNews__content__text">
                        {elem?.description}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            ))}
        </div>
        <div className="text-center visit-btn">
          <a href="News" className="btn btn-default button-link">
            For more information <i className="bi bi-arrow-up-right-circle"></i>
          </a>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default NewsScroller;
