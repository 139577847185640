import {
    Accordion,
    AccordionButton,
    Text,
    AccordionIcon,
    AccordionPanel,
    Box,
    AccordionItem,
    Center,
} from "@chakra-ui/react";
import React from "react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Helmet } from "react-helmet-async";

// import AccordionItem from "react-bootstrap/esm/AccordionItem";

const SportEquiInf = () => {
    return (
        <div
        // style={{ backgroundImage: 'linear-gradient(to bottom, #0a3272, white)' }}
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Event Organization - Bihar State Sports Authority</title>
                <meta name="description" content="Learn about BSSA's initiatives in organizing state-level sports events and competitions to promote athletic talent in Bihar." />
            </Helmet>

            {/* <Breadcrumb
                pageName="Sports-Equipment-Information"
                pageTitle="Sports-Equipment-Information"
                id="#Sports-Equipment-Information"
                img_url="./img/Sports-Equipment-Information-desktop.jpg"
                mobile_banner="./img/Sports-Equipment-Information-mobile.jpg"
            /> */}

            <Center p={6} m={6}>
                <Accordion defaultIndex={[0]}>
                    <Box boxShadow="lg" p="1" rounded="md" bg="#c0c9d0">
                        <Box
                            style={
                                {
                                    // border: "2px solid red",
                                }
                            }
                            // width="40%"
                            m="auto"
                            // borderTopLeftRadius="15px"
                            // borderTopRightRadius="15px"
                            // borderBottomLeftRadius="50%"
                            // borderBottomRightRadius="50%"

                            height="0px"
                        ></Box>
                        <Text
                            fontWeight="bold"
                            textTransform="uppercase"
                            fontSize={["18", "24"]}
                            letterSpacing="wide"
                            color="#fcffff"
                            px={[4, 10, 20]}
                            backgroundColor="#0b2651"
                            width="40%"
                            m="auto"
                            borderTopLeftRadius="15px"
                            borderTopRightRadius="15px"
                            borderBottomLeftRadius="15px"
                            borderBottomRightRadius="15px"
                        >
                            Organized Program
                        </Text>
                    </Box>

                    {/* new event added from here */}
                    <Box
                        bg="#0b244d"
                        marginLeft={3}
                        marginRight={3}
                        borderBottomLeftRadius={25}
                        borderBottomRightRadius={25}
                    >
                        {/* new data  */}

                        <AccordionItem>
                            <h1>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        Inauguration of Rajgir Sports Academy and Sports University – by Shri Nitish Kumar, Hon'ble Chief Minister, Bihar on 29th August 2024.
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h1>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                In this inaugural ceremony organized on the occasion of National Sports Day, the players participating in international competitions and medal winners of Bihar were honored with honorarium by the Hon'ble Chief Minister and the outstanding players of Bihar were honored with Bihar Khel Samman. On this occasion, the players of the Indian women's hockey team who came for the exhibition match were also welcomed by the Hon'ble Chief Minister by giving them a memento.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h1>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        Gift of job to outstanding players of the state under Medal Lao Naukri Pao Scheme
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h1>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                On 06 January 2023, at 11 am at Nek Samvad, Chief Minister's residence, 1 Anne Marg, Patna, Shri Nitish Kumar ji, Hon’ble Chief Minister of Bihar, handed over the appointment letters to the outstanding players of the state. The state has got a new identity due to the wonderful and brilliant performance of the players of Bihar in the world of sports.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h1>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        67th National School Games, Sepak Takraw Championship
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h1>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                The 67th National School Games, Sepak Takraw Championship was successfully organized from 03 January 2024 to 07 January 2024 at Patliputra Sports Complex, Kankarbagh, Patna. Result of Sepak Takraw Championship 2024 Finals:
                                <br />
                                <span style={{ color: "red" }}>Under-19 Boys </span> <br />
                                <span>1st: Bihar</span> <br />
                                <span>2nd Place: Delhi</span> <br />
                                <span> 3rd Place: Manipur </span> <br />
                                <span>4th Place: Telangana</span> <br />
                                <span style={{ color: "red" }}>Under-19 Girls</span> <br />
                                <span>1st: Manipur</span> <br />
                                <span>2nd Place: Bihar </span> <br />
                                <span> 3rd Place: Odisha</span> <br />
                                <span>4th Place: Kerala</span> <br />
                                <span style={{ color: "red" }}>Under-17 Boys</span> <br />
                                <span>1st: Manipur</span> <br />
                                <span>2nd Place: Delhi</span> <br />
                                <span>3rd Place: Bihar</span> <br />
                                <span>4th Place: Nagaland </span> <br />
                                <span style={{ color: "red" }}>Under-17 Boys</span> <br />
                                <span>1st: Manipur</span> <br />
                                <span>2nd Place: Rajasthan</span> <br />
                                <span> 3rd Place: Bihar</span> <br />
                                <span>4th Place: Andhra Pradesh</span> <br />
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h1>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        58th National Cross Country Championship 2024
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h1>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                The 58th National Cross Country Championship was successfully organized on 15th January 2024 at Magadh University Ground, Gaya District, Bihar.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h1>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        12th National School Chess Championship 2024
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h1>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                The 12th National School Chess Championship 2024 was organized under the joint aegis of Bihar State Sports Authority and All Bihar Chess Association from 6-10 February 2024 at Gyan Bhawan, Patna. Players of both boys and girls categories of under-7, 9, 11, 13, 15, and 17 age groups participated in this chess championship.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h1>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        67th National School Games Weightlifting Competition 2023-24
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h1>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                The 67th National School Games Weightlifting Competition 2023-24 was successfully organized from 13-16 February 2024 at Patliputra Sports Complex Kankarbagh, Patna, Bihar. In this competition, players of both boys and girls category from all over the country in the under-17 age group performed exceptionally.{" "}
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h1>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        Selection of women players for Kabaddi
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h1>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                Women Kabaddi players were selected on 23rd and 24th February 2024 at Patliputra Sports Complex, Kankarbagh, Patna. This selection test was conducted under the guidance of Dhyanchand Awardee, top Kabaddi coach Kavita Thakur ji.{" "}
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h1>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        67th National School Cricket Championship 2023-24
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h1>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                The 67th National School Cricket Championship was successfully organized at the Urja Stadium, Patna from 16 January 2024 to 23 January 2024.
                            </AccordionPanel>
                        </AccordionItem>

                        {/*  new data */}
                        <AccordionItem>
                            <h1>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        Volleyball Premier League
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h1>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                For the first time in Bihar, Bihar Volleyball Premier League was organized on 30th September at Patliputra Sports Complex, Kankarbagh, Patna under the joint aegis of Bihar State Sports Authority and Bihar Volleyball Association. A total of 08 teams participated in this league. Each team had 06 senior category and 04 junior and youth category players. Apart from this, 02 players in each team were of international level. This league was organized in 04 districts of the state namely Bhagalpur, Begusarai, Saran and Patna. In this league, the winning team was awarded a prize money of 7.5 lakhs, the runner-up team was awarded 5 lakhs and the team that came third was awarded 3 lakhs.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h1>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        Second Badminton Talent Search
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h1>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                The second badminton talent search program was successfully organized by Bihar State Sports Authority for boys and girls group under 15, under 17 and under 19 category at Bhagalpur Indoor Stadium from 28 September 2023 to 30 September 2023. During this talent search program, 35 badminton players were selected.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h1>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        Wrestling Selection Camp
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h1>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                A selection camp for boys and girls of wrestling under 14 and under-17 age group was successfully organized in Chhapra on 26th and 27th September 2023 for participation in school games.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h1>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        Simply Smiles Workshop
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h1>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                On behalf of Bihar State Sports Authority, Simply Smiles Workshop was successfully organized for all the coaches and district sports officials across the state on 18th September 2023 at Patliputra Sports Complex.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h1>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        Simply Periods Workshop
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h1>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                To achieve the goal of increasing health awareness among female players and trainers in the state, Bihar State Sports Authority successfully conducted the Simply Periods workshop from 13th to 18th September.
                            </AccordionPanel>
                        </AccordionItem>

                        {/* old event */}

                        <AccordionItem>
                            <h1>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        Khel Samman Samaroh 2023
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h1>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                The 'Bihar Khel Samman Samaroh' organized at Gyan Bhawan, Patna was formally inaugurated by the Chief Guest Shri Jitendra Kumar Rai, Hon’ble Minister of Art, Culture and Youth Department, on 29 August 2023. The special guest of this event was Mrs. Harjot Kaur Bamhra, Additional Chief Secretary, Department of Art, Culture and Youth. This year, in the Bihar Rajya Khel Samman Samaroh, a total of 411 people including 400 players and 11 coaches in the general and disabled category of 42 sports disciplines were given honor money of more than 5 crores, which is much more than last year. This year, there are a total of 17 players including 6 women and 11 men among the players who received international honors and a total of 283 players including 181 men and 162 women players among those who received national honors.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        Training Camp
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                Under the joint aegis of Bihar State Sports Authority and Shri Ramchandra Sports Science Institute, a grand training camp was organized for the coaches of Bihar and the coaches from across the state on 30 May 2023 at Dashrath Manjhi Institute Auditorium, Patna. South African trainer Andrew Gray and Shri V. Bhaskaran, Olympian, former captain and coach of the Indian hockey team participated in this training camp.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        Sports Conclave 2.0
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                Sports Conclave 2.0 was successfully organized on 19th and 20th May 2023 at Gyan Bhawan, Patna. The theme of the event was "Bihar - Becoming a Sports Centre of Excellence". The aim of the Sports Conclave was to focus the attention of the society and the state on excellent sports infrastructure, care and management of athletes, grassroots sports development and significant sports discipline like the  eSports. Organized by the government, the event was attended by officials, national and state sports federations, policy makers, business enterprises and several key stakeholders from the sports industry.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        NIDJAM 2023
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                With the aim of finding and grooming future winners from across the country at an early age, NIDJAM i.e. National Inter District Junior Athletics Meet was started by Athletics Federation of India (AFI) in the year 2003. NIDJAM is considered to be the world's largest basic talent search competition. In this, more than one lakh players participate in the competition at the district level, who come for inter-district competition after being selected from their district. In the 18th NIDJAM 2023 organized at Patliputra Sports Complex, Kankarbagh, Patna from 9 to 12 February, more than 6000 boys and girls under the age of 14 and 16 from 600 districts of the country along with more than 1500 of their coaches and managers participated in this NIDJAM. Out of which 513 players from 38 districts of Bihar also participated in it. NIDJAM organized in the year 2023 was the biggest event till date in Bihar.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        Raja Karna Archery (Target)
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                Raja Karna Open Bihar State Archery Competition is organized every year with the aim of providing a better platform to the talent of young archery players across the state. Players from all over the state participate in this competition. In which the players who get first, second and third place are honored and also given financial assistance by Bihar State Sports Authority.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        Khel Samman Samaroh 2018
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                On the occasion of National Sports Day celebrated on 29th August in honour of late Dhyan Chand ji on his birth anniversary, the Department of Art, Culture and Youth and Bihar State Sports Authority organizes 'Bihar Rajya Khel Samman' ceremony every year to honour outstanding players and coaches of Bihar who have made Bihar proud by winning medals in national and international competitions by giving them certificates and honorarium. Bihar Khel Samman Samaroh 2018 was organised at Urja Bhawan in Patna. In which 317 players and 6 coaches of 39 sports disciplines were honoured. In this recognition ceremony, an amount of Rs 3 crore 11 lakh was distributed among the players and coaches.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        Sports Conclave
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                The two-day Bihar Sports Conclave 2018 began with a grand inauguration at the Bihar Museum in the state capital Patna on Thursday, April 29. The primary objective of the event was to improve sports in Bihar overall. Bihar Sports Conclave 2018 was organized by the Bihar State Sports Authority with a mission to ensure better participation and success of Bihar's sporting talent at the 2028 Summer Olympics in Los Angeles
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        Daksha
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                Daksha is an annual competition which is organized at block, district, commissionerate and state level. This competition is organized for boys and girls of under-14, under-17 and under-19 age groups.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem
                            borderBottomLeftRadius={25}
                            borderBottomRightRadius={25}
                        >
                            <h2>
                                <AccordionButton>
                                    <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        fontSize={["16", "18"]}
                                        color="#f6ffff"
                                        p="3"
                                    >
                                        Tarang
                                    </Box>
                                    <AccordionIcon color="#f6ffff" fontSize={25} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel
                                pb={4}
                                textAlign="left"
                                fontSize={["13", "18"]}
                                color="#f6ffff"
                            >
                                Tarang is a school level competition which is conducted jointly by Bihar State Sports Authority and Education Department. Students of all government and private schools across the state participate in this competition. This competition is organized every year for students of under-12, under-14, under-17 and under-19 age groups.
                            </AccordionPanel>
                        </AccordionItem>
                    </Box>
                </Accordion>
            </Center>
        </div>
    );
};

export default SportEquiInf;
