import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import {
  Card,
  Grid,
  Box,
  extendTheme,
  CardHeader,
  Heading,
  CardBody,
  Center,
  Image,
  Text,
  Stack,
  CardFooter,
  Button,
  ButtonGroup,
  Divider,
  useEditable,
  SimpleGrid,
} from "@chakra-ui/react";

import api from "../../utils/ApiMethod";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Helmet } from "react-helmet-async";

const NationalAchievement = () => {
  const [data, setData] = useState([]);

  const radialGradient2 = "radial-gradient(circle, #ffefba, #ffffff);";

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/api/nationalachievement");
      setData(data);
      // console.log("Fetched data:", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>National Achievements - Bihar State Sports Authority</title>
        <meta name="description" content="Explore the accomplishments of Bihar's athletes in national sports competitions, showcasing their dedication and success." />
      </Helmet>

      <Breadcrumb
        pageName="National Achievements"
        pageTitle="National Achievements"
        id="#National-Achievements"
        // img_url="./img/Sports-recruitment-Policy-page-desktop.jpg"
        // mobile_banner="./img/Sports-recruitment-Policy-page-mobile.jpg"

        img_url={`https://api.biharsports.org/${data[0]?.bannerUrl?.replace(
          "public/images/",
          "images/"
        )}`}
        mobile_banner={`https://api.biharsports.org/${data[0]?.mobileBannerUrl?.replace(
          "public/images/",
          "images/"
        )}`}
      />
      <Heading p={8} fontSize="25px" background={radialGradient2}>
        {/* राष्ट्रीय उपलब्धियां */}
        {data[0]?.content}
      </Heading>

      {/* ------------------------------------horizontal Line---------------------------------------------------- */}
      <div
        style={{
          height: "4px",
          width: "100%",
          backgroundColor: " rgb(8, 44, 44)",
          paddingBottom: "3px",
        }}
      ></div>
      {/* ------------------------------------------------------------------------------------------------------- */}

      <Center background={radialGradient2}>
        <Grid
          templateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr", "1fr 1fr 1fr"]}
          gap={4}
          alignItems={"center"}
        >
          {data[0]?.NationalLevelAchi?.slice(0).reverse().map((item, index) => (
            <Box key={index} maxW="xl" p="2%">
              <motion.div
                key={index}
                initial={{
                  opacity: 0,
                  translateX: index % 2 === 0 ? -50 : 50,
                  translateY: -50,
                }}
                whileInView={{ opacity: 1, translateX: 0, translateY: 0 }}
                transition={{ duration: 0.3, delay: index * 0.2 }}
              >
                <Image
                  // src={item.image}
                  src={`https://api.biharsports.org/${item?.image.replace(
                    "public/images/",
                    "images/"
                  )}`}
                  alt="Green double couch with wooden legs"
                  borderRadius="lg"
                  height="100%"
                  width="100%"
                />
                <Stack mt="6" spacing="3">
                  <Heading size="md">{item?.sportName}</Heading>
                  <Text>{item?.sportDetails}</Text>
                </Stack>
              </motion.div>
            </Box>
          ))}
        </Grid>
      </Center>
    </div>
  );
};

export default NationalAchievement;
